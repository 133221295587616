import React from 'react'
import Banner from '../components/Banner';
import Layout from '../layout/Layout';
import MainQuote from '../components/Quote';
import Seo from '../layout/Seo';

const planning = ({ pageContext }) => {

    const { locale } = pageContext;
    function Child({locale}){
        return(<>
         <Seo 
                title="Homepage" 
                description="This is the homepage of my website"
                pathname="https:localhost"
            />
            <Banner title={locale.planning.banner.title} subtitle={locale.planning.banner.subtitle} />
            <MainQuote title={locale.planning.MainQuote} locale={locale}  />
        </>)
    }
    return (
        <Layout locale={locale} background="bg-image-planning">
            {props => <Child {...props} />}
        </Layout>
    )
}

export default planning
